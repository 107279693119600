<template>
   <div class="p-10">
      <section>
         
      </section>
   </div>
</template>

<script>
export default {
   data() {
      return {
         isLoading: false
      }
   }
}
</script>